.navlogo {
  width: 50%;
}

.logoimg {
  /* width: 30%; */
  height: 50px;
  /* width: 100px; */
}

.logoimghead {
  width: 78%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:disabled {
  cursor: not-allowed;
}

*::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  box-sizing: border-box;

  position: absolute;
  width: 14px;
  height: 321.96px;
  left: 537px;
  top: 0px;

  border: 1px solid #d9d9d9;
}

*::-webkit-scrollbar-thumb {
  width: 14px;
  background: #a07fa7;
}

#overlays {
  width: 100%;
  /* height: 100%; */
}

.invalid-msg {
  color: red;
  font-size: 16px;
}

.active-page-btn {
  background-color: #c8aace !important;
  color: white !important;
}

#loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.text-center {
  text-align: center;
  width: 100%;
}

.loader {
  --loader-size: 50px;
  --loader-border-size: 4px;
  --loader-border-color: #c8aace;
  width: var(--loader-size);
  height: var(--loader-size);
  border: var(--loader-border-size) solid var(--loader-border-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  /*   background-color: transparent; */
  border-radius: 50%;
  position: relative;
  animation: rotateX 1s infinite linear;
}

.loader::before {
  content: "";
  border: var(--loader-border-size) solid var(--loader-border-color);
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  animation: rotateX 0.5s infinite linear reverse;
}

@keyframes rotateX {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .logoimg {
    width: 50%;
  }
}
